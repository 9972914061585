import ReactDOM from 'react-dom';
import React from 'react';
import {IntlProvider} from 'react-intl';
import PageWrapper from '../../components/PageWrapper';
import Login from './Login';
import LoginCK4MS from './LoginCK4MS';

const {messages, ...props} = window.__APP_INITIAL_STATE__;

const hasEducationCoreAnonymousAccess = props.user.features.anonymousAccess && props.featureFlags?.anonymousAccess;

ReactDOM.render(
  <IntlProvider locale={props.locale} messages={messages}>
    <PageWrapper {...props} pageWrapperClass='s-ckm-login'>
      {hasEducationCoreAnonymousAccess ? <LoginCK4MS {...props} /> : <Login {...props} />}
    </PageWrapper>
  </IntlProvider>,
  document.getElementById('app')
);
