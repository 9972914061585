import { isEmpty } from 'ramda';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, defineMessages } from 'react-intl';
import AuthPopout from '../../components/common/AuthPopout';
import InternalLink from '../../components/common/InternalLink';
import Form from '../../components/form/Form';
import FormCheckboxSingle from '../../components/form/FormCheckboxSingle';
import FormTextInput from '../../components/form/FormTextInput';
import { MainContext } from '../../context/main-context';
import CKApi from '../../services/api-helper';
import { eolsLogin } from '../../services/eols';
import { trackEvent } from '../../utils/eventTrackUtils';
import queryStringUtils from '../../utils/queryStringUtils';
import InstitutionLoginLinksCK4MS from './InstitutionLoginLinksCK4MS';
import { messages } from './Login';

const messagesCK4MS = defineMessages({
  LoginCK4MSUsernameLabel: {
    id: 'LoginPage.ck4ms.label.username',
    defaultMessage: 'Username'
  },
  LoginCK4MSNotRegister: {
    id: 'LoginPage.ck4ms.text.notRegistered',
    defaultMessage: 'Not registered?'
  },
  LoginCK4MSRegisterNow: {
    id: 'LoginPage.ck4ms.link.registerNow',
    defaultMessage: 'Register now'
  }
});

const getOtherInstitutionLoginHref = (studyToolsLoginType) => {
  if (studyToolsLoginType) {
    return queryStringUtils.addStudyToolsParam('/institution-login', studyToolsLoginType);
  }

  const { target } = queryStringUtils.parse(window.location.search);

  const url = target ? `/institution-login?target=${target}` : '/institution-login';

  return url;
};

class LoginCK4MS extends Component {
  state = {
    apiError: {},
    loggingIn: false,
    isPopupOpen: false,
    popupType: ''
  };

  static contextType = MainContext;

  otherInstitutionLoginHref = getOtherInstitutionLoginHref(this.props.studyToolsLoginType);

  setDataAnalyticsLogin = () => {
    trackEvent('loginStart');
  };

  handleValidSubmit = (userInputs) => {
    localStorage.removeItem('ckst-performance-start-data');
    localStorage.removeItem('ckst-performance-end-data');
    this.setDataAnalyticsLogin();
    const params = {
      username: userInputs.username,
      password: userInputs.password,
      remember_me: userInputs.rememberMe
    };

    CKApi.post('/student/api/auth/login', params)
      .then((response) => { this.handleApiSuccess(response); })
      .catch(this.handleApiError);
  };

  formattedErrorMessage = () => {
    return (
      <>
        <span>{this.context.intl.formatMessage(messages.LoginNoAccess)}</span>
        <div>
          <span>{this.context.intl.formatMessage(messages.LoginTroubleshooting)}</span>
          <span className='c-ckm-link' role='button' tabIndex='0' onClick={this.showPopup('loginMethods')} onKeyDown={this.handleKeyDown}>
            <FormattedMessage
              id='LoginPage.message.loginMethodButton'
              defaultMessage=' here.'
            />
          </span>
        </div>
      </>
    );
  };

  handleApiError = ({ response }) => {
    let message;
    trackEvent('loginFailure');
    if (!isEmpty(this.state.apiError)) this.setState({ apiError: {} });

    switch (response.status) {
      case 401:
        message = { message: this.context.intl.formatMessage(messages.LoginInvalidCredentials), name: 'username' };
        break;
      case 403:
        message = { message: this.formattedErrorMessage };
        break;
      default:
        message = { message: this.context.intl.formatMessage(messages.LoginFailed) };
    }
    this.setState({ apiError: message });
  };

  handleApiSuccess = (response) => {
    this.setState({ apiError: {} });
    this.handleLoginComplete(response);
  };

  handleLoginComplete = (response) => {
    if (response.data.status === 'PATH_CHOICE') {
      this.context.showPathChoice(response.data.path_choices);
      this.setState({ loggingIn: true });
    } else {
      trackEvent('newPage', {
        loginSuccess: 'true'
      });
      this.doRedirect();
    }
  };

  handleKeyDown = (evt) => {
    if (evt?.keyCode === 13 || evt?.keyCode === 32) {
      this.showPopup(evt);
    }
  };

  showPopup = (type) => (evt) => {
    evt.preventDefault();
    this.setState({
      isPopupOpen: true,
      popupType: type
    });
  };

  handlePopoutClose = () => {
    this.setState({
      isPopupOpen: false,
      popupType: ''
    });
  };

  doRedirect = async () => {
    const { user } = this.context;
    const hasAssessmentAccess = user.hasAssessmentAccess();

    if (hasAssessmentAccess) {
      await eolsLogin();
    }

    if (this.props.studyToolsLoginType) {
      const onlineStudyTools = this.props.studyToolsLoginType === 'online';
      this.context.doRedirect(`/api/vst/redirect?online=${onlineStudyTools}`);
    } else {
      const parsedQueryString = queryStringUtils.parse(window.location.search);
      this.context.doRedirect(parsedQueryString.target || '/');
    }
  };

  componentWillMount () {
    const { user } = this.context;
    if (user && user.isPathChoice()) {
      this.context.showPathChoice(user.path_choices);
    }
  }

  componentDidMount () {
    this.setState({ title: this.context.intl.formatMessage(messages.LoginPageTitle) });
  }

  render () {
    const { intl } = this.context;

    return (
      <div className='c-ckm-auth-ck4ms'>
        <div className='c-ckm-auth-ck4ms__inner-container'>
          <Helmet>
            <title>{this.state.title}</title>
          </Helmet>
          {this.state.isPopupOpen &&
            <AuthPopout
              handlePopoutClose={this.handlePopoutClose}
              type={this.state.popupType}
              otherInstitutionLoginHref={this.otherInstitutionLoginHref}
              intl={intl}
            />}
          <div>
            <h1 className='c-ckm-auth-ck4ms__title'>
              <FormattedMessage
                id='LoginPage.head.yourDetails'
                defaultMessage='Log in'
              />
            </h1>
            <p className='o-els-flex-layout__item c-ckm-auth-ck4ms__description'>
              <span>
                <FormattedMessage
                  id='LoginPage.ck4ms.text.description.part1'
                  defaultMessage='Unlock personalized features with'
                />
              </span>
              <span className='c-ckm-auth-ck4ms__description--bold'>
                <FormattedMessage
                  id='LoginPage.ck4ms.text.description-part2'
                  defaultMessage='one account'
                />
              </span>
              <span>
                <FormattedMessage
                  id='LoginPage.ck4ms.text.description-part3'
                  defaultMessage='across ClinicalKey and ClinicalKey Student'
                />
              </span>
            </p>
            <div className='c-ckm-auth-ck4ms__block'>
              <article className='c-ckm-auth-ck4ms__column'>
                <div className='c-ckm-auth-ck4ms__column__left' role='region' aria-label={intl.formatMessage(messages.LoginArialabel)}>
                  {this.state.loggingIn ? (
                    <h3>
                      <FormattedMessage
                        id='LoginPage.label.loggingIn'
                        defaultMessage='Logging in...'
                      />
                    </h3>
                  ) : (
                    <Form
                      intl={intl}
                      validSubmit={this.handleValidSubmit}
                      apiError={this.state.apiError}
                      apiSuccess={this.state.apiSuccess}
                      buttonSubmitText={intl.formatMessage(messages.LoginButtonSubmit)}
                      hasEducationCoreAccess
                    >
                      <FormTextInput
                        label={intl.formatMessage(messagesCK4MS.LoginCK4MSUsernameLabel)}
                        type='text'
                        name='username'
                        isRequired
                        autocomplete='off'
                        requiredErrorMessage={intl.formatMessage(messages.LoginNoUsername)}
                        mouseflowIgnore
                        hadEducationCoreAccess
                      />
                      <FormTextInput
                        label={intl.formatMessage(messages.LoginPasswordLabel)}
                        type='password'
                        name='password'
                        autocomplete='off'
                        isRequired
                        requiredErrorMessage={intl.formatMessage(messages.LoginNoPassword)}
                        mouseflowIgnore
                        showPasswordToggle
                        showPasswordLabel={intl.formatMessage(messages.showPassword)}
                        hidePasswordLabel={intl.formatMessage(messages.hidePassword)}
                        hadEducationCoreAccess
                      />
                      <p className='c-ckm-form-sub-text'>
                        <InternalLink href='/forgot-password'>
                          <FormattedMessage
                            id='LoginPage.ck4ms.link.forgotPassword'
                            defaultMessage='Forgotten username or password?'
                          />
                        </InternalLink>
                      </p>
                      <FormCheckboxSingle
                        name='rememberMe'
                        label={intl.formatMessage(messages.LoginRememberLabel)}
                        initialCheck
                        instructionsID='u-ckm-login-warningSharedData'
                      />
                    </Form>
                  )}
                </div>
                <div className='c-ckm-auth-ck4ms__info-block'>
                  <span>{intl.formatMessage(messagesCK4MS.LoginCK4MSNotRegister)}</span>
                  <a href='/student/registration'>
                    <span>{intl.formatMessage(messagesCK4MS.LoginCK4MSRegisterNow)}</span>
                  </a>
                </div>
              </article>
              {
                !this.props.bulkId &&
                  <aside className='c-ckm-auth-ck4ms__column'>
                    <div className='c-ckm-auth-ck4ms__column__right'>
                      <InstitutionLoginLinksCK4MS
                        otherInstitutionLoginHref={this.otherInstitutionLoginHref}
                        setDataAnalyticsLogin={this.setDataAnalyticsLogin}
                      />
                    </div>
                  </aside>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginCK4MS;

LoginCK4MS.displayName = 'LoginCK4MS';
