import { LinkElement } from '@els/els-react--link-element';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ShibbolethIDPLink from '../../components/common/ShibbolethIDPLink';
import { OPENATHENS_ENTITYID } from '../../constants';

const InstitutionLoginLinksCK4MS = (props) => {
  const { otherInstitutionLoginHref, setDataAnalyticsLogin } = props;

  return (
    <section className='c-ckm-auth-ck4ms__institution-login'>
      <div>
        <h3 className='c-ckm-auth-ck4ms__institution-login__title'>
          <FormattedMessage
            id='LoginPage.link.otherInst'
            defaultMessage='Log in via your institution'
          />
        </h3>
        <ul className='c-ckm-auth-ck4ms__institution-login__list'>
          <li className='c-ckm-auth-ck4ms__institution-login__list-item'>
            <ShibbolethIDPLink
              entityID={OPENATHENS_ENTITYID}
              studyToolsLoginType={props.studyToolsLoginType}
              onClick={setDataAnalyticsLogin}
              hasEducationCoreAccess
            >
              <FormattedMessage
                id='LoginPage.link.openAthensLoginCK'
                defaultMessage='OpenAthens login'
              />
            </ShibbolethIDPLink>
            <p className='c-ckm-auth-ck4ms__institution-login__list-item__description'>
              <FormattedMessage
                id='LoginPage.text.openAthensDescription'
                defaultMessage='If your institution uses the OpenAthens service, you may log in to ClinicalKey using your username and password for your institution.'
              />
            </p>
          </li>
          <li className='c-ckm-auth-ck4ms__institution-login__list-item'>
            <LinkElement
              href={`/student${otherInstitutionLoginHref}`}
              iconSprite='ChevronRight'
              iconEmphasis
              iconSize='xs'
            >
              <FormattedMessage
                id='LoginPage.link.otherInstitutionLogin'
                defaultMessage='Other institution login'
              />
            </LinkElement>
            <p className='c-ckm-auth-ck4ms__institution-login__list-item__description'>
              <FormattedMessage
                id='LoginPage.text.institutionLoginDescription'
                defaultMessage='For services other than OpenAthens, log in to ClinicalKey using your username and password for your institution.'
              />
            </p>
          </li>
        </ul>
        <hr className='c-els-divider c-els-divider--1o2' />
      </div>
      <div>
        <h3 className='c-ckm-auth-ck4ms__institution-login__title'>
          <FormattedMessage
            id='LoginPage.text.RemoteAccess'
            defaultMessage='Remote access'
          />
        </h3>
        <div className='c-ckm-auth-ck4ms__institution-login__remote-access-block'>
          <p>
            <FormattedMessage
              id='LoginPage.text.remoteAccessDescription'
              defaultMessage='Access ClinicalKey from outside of your institution’s network by enabling remote access. This is strongly recommended for users of the ClinicalKey mobile app.'
            />
          </p>
          <LinkElement
            href='https://www.clinicalkey.com/#!/remote-access'
            iconSprite='ChevronRight'
            iconEmphasis
            iconSize='xs'
            className='c-ckm-auth-ck4ms__institution-login__remote-access-link'
          >
            <FormattedMessage
              id='LoginPage.link.applyNow'
              defaultMessage='Apply now'
            />
          </LinkElement>
        </div>
      </div>
    </section>
  );
};

InstitutionLoginLinksCK4MS.propTypes = {
  otherInstitutionLoginHref: PropTypes.string
};

InstitutionLoginLinksCK4MS.displayName = 'InstitutionLoginLinksCK4MS';

export default InstitutionLoginLinksCK4MS;
